// Themes

  // LIGHT THEME

  $color-primary: #dddfe6;
  $color-primary-light: #f4f5f9;
  $color-secondary: #f1404b;
  $color-text-dark: #414141;

  $color-black: #000;

  // DARK THEME

  $color-primary-dark: #313131;
  $color-primary-dark-light: #414141;
  $color-secondary-dark: #ca3e47;
  $color-text-light: #dddfe6;