.contact {
  position: relative;
  z-index: 9;
  margin-top: -25rem;
  padding-top: 25rem;
  padding-bottom: 10rem;
  @include sectionBottom;

  &__content-div {
    padding: 2rem;


    &--form {
      max-width: 45%;
    }

    &--form-inputs {
      background-color: transparent;
    }
  }

}

@media only screen and (max-width: 770px) {

  .contact {
    margin-top: -40rem;
    padding-top: 40rem;

    &__content-div {
      padding: 2rem;

      &--description {
        margin-right: 0;
        padding-right: 0;
        border-right: none !important;
      }

      &--hr {
        width: 70%;
        margin: 4rem auto;
        height: 2px;
      }

      &--form {
        margin-top: 2rem;
        max-width: 70%;
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .contact {
    margin-top: -30rem;
    padding-top: 30rem;
    
    &__content-div {
      &--parag {
        font-size: 1rem;  

        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }

      &--form {
        max-width: 90%;
      }
    }
  }
}